import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/apps/blog/src/components/PageLayout/PageLayout.tsx";
import { Email, Container, PageOffset } from 'components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`What Are Cookies`}</h2>
    <p>{`As is common practice with almost all professional websites use cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how I use it and why I sometimes need to store these cookies. I will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.`}</p>
    <p>{`For more general information on cookies, please read `}<a href="https://www.cookieconsent.com/what-are-cookies/">{`"What Are Cookies"`}</a>{`.`}</p>
    <h2>{`How I Use Cookies`}</h2>
    <p>{`I use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.`}</p>
    <h2>{`Disabling Cookies`}</h2>
    <p>{`You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.`}</p>
    <h2>{`The Cookies I Set`}</h2>
    <Container mdxType="Container">
      <ul>
        <li parentName="ul">{`Site preferences cookies.`}<br />{`
In order to provide you with a great experience on this site I provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences I need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.`}</li>
      </ul>
    </Container>
    <h2>{`Third Party Cookies`}</h2>
    <p>{`In some special cases I also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.`}</p>
    <Container mdxType="Container">
      <ul>
        <li parentName="ul">{`This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that I can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so I can continue to produce engaging content. All statistics I gather with Google Analytics is completely anonymous.`}<br />{`
For more information on Google Analytics cookies, see the official Google Analytics page.`}</li>
      </ul>
    </Container>
    <h2>{`More Information`}</h2>
    <p>{`Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.`}</p>
    <p>{`However, if you are still looking for more information then you can contact me by sending an email to `}<Email mdxType="Email" /></p>
    <PageOffset mdxType="PageOffset" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      